<template>
  <div class="service-times-presentations template-2">
    <page-header class="mx-lg">
      <h1>{{ translations.tcServiceTimesOrPresentations }}</h1>
      <BackJump @reload_host="pageLoad()" :obj="backJumpObj" :vis="true" :i18n="translations.components"></BackJump>
    </page-header>

    <page-body class="mx-lg">
      <section class="section-1">
        <div class="bg-block p-4">
          <header class="mb-5">
            <h2>
              {{ translations.tcServiceTimes }}
              <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcServiceTimes">
                <i-tooltip />
              </span>
            </h2>
            <ToggleArrow v-if="toggle == true" @click="toggleMe = !toggleMe" section="toggleMe" :status="toggleMe">
            </ToggleArrow>
          </header>
          <b-button v-if="toggleMe && iCanSee(secured_church_service_times_add_controls.add_church_service_time_button)"
            variant="primary" @click="handleAddNewTime($event)">{{ translations.tcAddServiceTime }}</b-button>
          <div v-if="toggleMe && translations.components" class="service-times d-flex flex-wrap">
            <ServiceCard v-for="(service, index) in churchDetail.services" :i18n="translations.components" :key="index"
              :obj="service" :secure_delete_keys="secured_church_service_times_delete_controls"
              :secure_edit_keys="secured_church_service_times_edit_controls"
              @edit_requested="handleServiceEditRequest(service.cti_key)"
              @delete_approved="handleServiceDeleteRequest(service.cti_key)"></ServiceCard>
            <p v-if="churchDetail.services.length === 0">{{ translations.tcNoServiceTimesFound }}</p>
          </div>
        </div>
      </section>
      <section class="section-2">
        <div class="bg-block p-4">
          <header class="mb-5">
            <h2>
              {{ translations.tcPresentations }}
              <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcPresentations">
                <i-tooltip />
              </span>
            </h2>
            <ToggleArrow v-if="toggle == true" @click="Section2 = !Section2" section="Section2" :status="Section2">
            </ToggleArrow>
          </header>
          <b-button v-if="Section2 && iCanSee(secured_presentation_add_controls.add_church_presentation_button)"
            variant="primary" class="mr-2" @click="handleAddPresentationClick">{{ translations.tcAddPresentation }}
          </b-button>
          <b-button v-if="Section2 && presentations.length > 6" variant="tertiary" @click="showAll = !showAll">
            <span v-if="!showAll">{{ translations.tcViewAllPresentations }}</span>
            <span v-else>{{ translations.tcHideExtraPresentations }}</span>
          </b-button>
          <div v-if="Section2" class="pres-dates d-flex flex-wrap">
            <div class="col-lg-4 col-md-4" v-for="(pres, index) in presentations" :key="index">
              <DateCard :obj="pres.date" v-if="index < 6 || showAll" :i18n="translations.common">
                <Service :obj="pres" :linkOut="linkOut" @link_out="handlePresentationLink($event)"
                  :i18n="translations.components" />
              </DateCard>
            </div>
            <p v-if="presentations.length === 0">{{ translations.tcNoPresentationsFound }}</p>
          </div>
        </div>
        <AddServiceTime :church_name="churchDetail.name" :church_key="selectedChurchKey"
          :service_being_edited="service_to_edit" @timeAddSuccess="handleTimeAddSuccess"></AddServiceTime>
      </section>
    </page-body>

    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJump.vue'
import AddServiceTime from '@/components/AddServiceTime.vue'
import ServiceCard from '@/components/service-card/ServiceCard.vue'
import DateCard from '@/components/date-card/DateCard.vue'
import Service from '@/components/date-card/Service.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import { numericMixin } from '@/mixins/numericMixin'
import pageHeader from '@/components/page-components/PageHeader.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import 'vue2-timepicker/dist/VueTimepicker.css'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'service-times-presentations',
  mixins: [numericMixin, translationMixin],
  data() {
    return {
      translations: {},
      service_to_edit: null,
      toggle: true,
      showAll: false,
      toggleMe: true,
      Section2: true,
      backJumpObj: {
        link: '#',
        location: '',
      },
      linkOut: true,
      secured_church_service_times_add_controls: {
        add_church_service_time_button: '16a5255d-9ea9-43ef-b98b-24a255f55fbc',
      },
      secured_church_service_times_delete_controls: {
        delete_church_service_time_button: '5dd86b34-2702-4dda-a3d3-1dbca2ca05de',
      },
      secured_church_service_times_edit_controls: {
        edit_church_service_time_button: '0ca84e1f-224a-4c75-b54f-6fa4b3e1050f',
      },
      secured_presentation_add_controls: {
        add_church_presentation_button: '2e18fcc4-b81d-4b54-804e-9d9e8932d810',
      },
    }
  },
  methods: {
    ...mapActions({
      clearSinglePresentation: 'churchMinistry/clearSinglePresentation',
      deleteChurchServiceTime: 'churchMinistry/deleteChurchServiceTime',
      loadChurchDetail: 'churchMinistry/loadChurchDetail',
      loadPresentationsAndServiceTimes: 'churchMinistry/loadPresentationsAndServiceTimes',
      setLoadingStatus: 'menu/setLoadingStatus',
      setPresentationEditKey: 'churchMinistry/setPresentationEditKey',
      setSelectedMeetingKey: 'user/setSelectedMeetingKey',
    }),
    updateCamp(value) { },
    async handleTimeAddSuccess() {
      await this.pageLoad()
    },
    async handleAddPresentationClick() {
      await Promise.all([await this.setPresentationEditKey(null), await this.clearSinglePresentation()]).then(() => {
        this.$router.push('/programs/cm/churchProfile/add-church-presentation')
      })
    },
    async handlePresentationLink(data) {
      await this.setSelectedMeetingKey(data.mtg_key)
      this.$router.push({ path: '/programs/cm/churchProfile/view-church-presentation' })
    },
    async handleServiceEditRequest(cti_key) {
      this.service_to_edit = await this.churchDetail.services.find((se) => se.cti_key === cti_key)
      this.showModal(null, 'newServiceModal')
    },
    async handleServiceDeleteRequest(cti_key) {
      let result = true
      this.setLoadingStatus(true)
      await Promise.all([(result = await this.deleteChurchServiceTime(cti_key)), await this.pageLoad()]).then(
        (result) => {
          this.$swal({
            icon: result ? 'success' : 'error',
            showCancelButton: false,
            text: result ? this.translations.tcDeleteSuccessful : this.translations.tcDeleteFailed,
            confirmButtonText: this.translations.tcOk,
          })
        }
      )
    },
    async handleViewAllPresentationsClick() {
      if (this.selectedChurchKey !== null && this.isGuid(this.selectedChurchKey)) {
        this.$router.push('/programs/cm/presentations/1')
      }
    },
    handleAddNewTime(evt) {
      this.service_to_edit = null
      this.showModal(null, 'newServiceModal')
    },
    async pageLoad() {
      try {
        this.setLoadingStatus(true),
          await Promise.all([
            await this.loadChurchDetail(),
            this.loadPresentationsAndServiceTimes(),
            (this.backJumpObj.location = this.churchDetail.name),
            (this.backJumpObj.link = '/programs/cm/church-profile'),
          ])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    showModal(e, modalName) {
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        await this.getViewTranslations(),
        await this.getComponentTranslations('back-jump', 'quick-jump', 'service-card', 'service-date-card'),
        await this.getComponentTranslations(
          'common.attire',
          'common.church-service-type',
          'common.days',
          'common.months-abbreviations'
        ),
        await this.pageLoad(),
      ]).then((results) => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      churchDetail: 'churchMinistry/churchDetail',
      iCanSee: 'user/iCanSee',
      prefCulture: 'user/userPreferredCulture',
      presentations: 'churchMinistry/presentations_scheduled_by_church',
      selectedChurchKey: 'user/userSelectedChurchKey',
    }),
  },
  components: {
    AddServiceTime: AddServiceTime,
    BackJump: BackJump,
    DateCard: DateCard,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    Service: Service,
    ServiceCard: ServiceCard,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';

.service-times-presentations {
  header h1 {
    padding-bottom: 80px;

    @include breakpoint(sm) {
      padding-bottom: 0;
    }
  }

  h2 {
    margin: 0;
    color: #000000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4px;
    line-height: 44px;
    text-transform: uppercase;
    display: flex;

    .i-tooltip {
      position: relative;
      top: -5px;
      margin-left: 10px;
      line-height: 0;

      svg path {
        fill: #000;
      }
    }
  }

  section {
    margin-bottom: 40px;

    .p-4 {
      padding: 40px 30px 20px 30px !important;

      @include breakpoint(sm) {
        padding: 20px 24px 20px 24px !important;
      }
    }

    header {
      display: flex;

      h2 {
        flex: 1 1 auto;

        @include breakpoint(sm) {
          margin-bottom: 30px;
        }
      }

      .toggle-arrow {
        align-self: flex-start;
        margin-top: 5px;
      }
    }

    @include breakpoint(sm) {
      .btn {
        width: 100%;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .service-times,
  .pres-dates {
    margin-top: 35px;
  }

  .pres-dates {
    .col-lg-4v {
      .date-card {
        width: auto;
        margin-right: 15px;

        @include breakpoint(sm) {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.vue__time-picker .dropdown .select-list {
  width: 15em;
}

.date-card,
.service-card {
  margin-bottom: 15px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

  h3 {
    color: $teal-800;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }
}
</style>
